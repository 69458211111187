//const website = require('./website')

module.exports = {
  fr: {
    default: true,
    label: "Fr",
    path: "fr",
    locale: "fr",
    siteLanguage: "fr",
    ogLang: "fr_FR",
    projects: "Projets",
    about: "À propos",
    contact: "Contact",
    close: "Fermer",
    login: "Se connecter",
    myAccount: "Mon compte",
    cart: "Panier",
    textSize: "Taille",
    weight: "Graisse",
    monospace: "Monochasse",
    italic: "Italique",
    buy: "Acheter",
    saveCustomInstance: "Sauvegarder l'instance",
    families: "familles",
    success: "Merci de vous être abonné!",
    error: "Une erreur interne inattendue s'est produite.",
    empty: "Vous devez écrire un e-mail.",
    duplicate: "Trop de tentatives d'abonnement pour cette adresse email",
    cookieButtonText: "It's okay.",
    cookieDeclineButtonText: "No, thanks.",
    cookieMessage:
      "We use cookies to enhance the user experience. Learn more in our <a href='https://www.dialogue-works.com/campaign/' target='_blank'>privacy settings.</a>",
    viewInvoice: "Voir la facture",
  },
  en: {
    default: false,
    label: "En",
    path: "en",
    locale: "en",
    siteLanguage: "en",
    ogLang: "en_GB",
    projects: "Projects",
    about: "About",
    contact: "Contact",
    close: "Close",
    login: "Login",
    myAccount: "My account",
    cart: "Cart",
    textSize: "Size",
    weight: "Weight",
    monospace: "Monospace",
    italic: "Italic",
    buy: "Buy",
    saveCustomInstance: "Save custom",
    families: "families",
    success: "Thanks for subscribing!",
    error: "An unexpected internal error has occurred.",
    empty: "You must write an e-mail.",
    duplicate: "Too many subscribe attempts for this email address",
    cookieButtonText: "It's okay.",
    cookieDeclineButtonText: "No, thanks.",
    cookieMessage:
      "We use cookies to enhance the user experience. Learn more in our <a href='https://www.dialogue-works.com/campaign/' target='_blank'>privacy settings.</a>",
    viewInvoice: "View invoice",
  },
}
