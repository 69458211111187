// import ReactDOM from "react-dom";
import React, { useState, useEffect, createContext } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Header from "./Header"
import Footer from "./Footer"
// import phone from "./shop/phone.html";
// const phone = require("./shop/phone.js");
import { ShopWrapper } from "./shop/ShopContext"
import { Toaster } from "react-hot-toast"
import CookieConsent from "./ui/CookieConsent"
const WrapperContext = createContext()

const query = graphql`
  query {
    settings: sanitySettings {
      customCSS
    }
  }
`

const Layout = ({ children, pageContext }) => {
  const { settings } = useStaticQuery(query)
  const { customCSS } = settings
  // const settings = {}
  // console.log(settings)
  const [direction, setDirection] = useState("up")

  useEffect(() => {
    if (customCSS) _addCustomCSS()

    _onScroll()
    _format()
    window.addEventListener("scroll", _onScroll)
    window.addEventListener("resize", _format)

    return () => {
      window.removeEventListener("scroll", _onScroll)
      window.removeEventListener("resize", _format)
    }
  }, [])

  const _addCustomCSS = () => {
    const styleSheet = document.createElement("style")
    styleSheet.innerText = customCSS
    styleSheet.id = "settings-custom-css"
    document.head.appendChild(styleSheet)
  }

  let _prevScrollTop = 0
  const _onScroll = () => {
    // console.log(window.pageYOffset)
    window.pageYOffset > _prevScrollTop
      ? setDirection("down")
      : setDirection("up")

    if (window.pageYOffset <= 30) {
      setDirection("")
    }

    _prevScrollTop = window.pageYOffset
  }
  const _format = () => {
    document.documentElement.style.setProperty(
      "--app-height",
      window.innerHeight + "px"
    )

    const headerBounding = document
      .querySelector("header")
      .getBoundingClientRect()
    // console.log(headerBounding);

    document.documentElement.style.setProperty(
      "--header-height",
      headerBounding.height + "px"
    )
  }
  // console.log(direction)
  return (
    <WrapperContext.Provider value={{ settings }}>
      <div id="page" className={direction}>
        <ShopWrapper>
          <Header direction={direction} />

          <main className="h-main pt-header-height">{children}</main>
          <Footer />
        </ShopWrapper>

        <Toaster
          position="bottom-right"
          reverseOrder={true}
          toastOptions={{
            className: "",
            style: {
              background: "var(--bg)",
              border: "1px solid var(--color-primary)",
              padding: "0em 1em",
              color: "var(--color-primary)",
              borderRadius: "100%",
            },
          }}
        />
        <CookieConsent />
      </div>
    </WrapperContext.Provider>
  )
}

export { WrapperContext, Layout }
