import React from "react"
//import { StaticQuery, graphql } from "gatsby"
import { Link, graphql, useStaticQuery } from "gatsby"
import clsx from "clsx"
// import { WrapperContext } from "./Layout";
import Menu from "./Menu"
import Burger from "./ui/Burger"
import LocaleSwitcher from "./ui/LocaleSwitcher"
import Login from "./shop/Login"
import Cart from "./shop/Cart"
import { _linkResolver, _localizeField } from "../core/utils"
import Switch from "./ui/Switch"
import { useEffect } from "react"
import { subscribe, unsubscribe } from "pubsub-js"
import ButtonTestCall from "./ui/ButtonTestCall"

const query = graphql`
  query {
    # siteBuildMetadata {
    #   buildTime
    # }
    sanityHeader {
      siteName
      nav {
        label {
          fr
          en
        }
        link {
          ... on SanityPageModulaire {
            _type
            title
            slug {
              current
            }
          }
        }
      }
    }
  }
`

const Header = ({ direction }) => {
  const {
    // siteBuildMetadata: { buildTime },
    sanityHeader: { siteName, nav },
  } = useStaticQuery(query)

  useEffect(() => {
    const token = subscribe("TOGGLE_DARK_MODE", (e, d) => {
      console.log(d)
      if (d) {
        document.documentElement.classList.add("dark-mode")
      } else {
        document.documentElement.classList.remove("dark-mode")
      }
    })

    return () => unsubscribe(token)
  }, [])

  return (
    <header className={clsx("py-md fixed ", direction)} id="site-header">
      <nav id="nav-desktop" className="hidden-sm px-md">
        <div className="row z-10 !flex-nowrap ">
          <div className="col-md-2 col-xs">
            <Link to="/">{siteName}</Link>
          </div>
          {nav.map((li, i) => (
            <div key={i} className="col-md-2 col-xs menu-item">
              <Link to={_linkResolver(li.link)} className="md:pr-md">
                {_localizeField(li.label)}
              </Link>
            </div>
          ))}
          <div className="col-md-1 col-xs menu-item">
            <LocaleSwitcher />
          </div>
          <div className="col-md-3 col-xs">
            <div className="flex justify-end items-center">
              <Switch size={12} event="TOGGLE_DARK_MODE" />
              <div className="flex justify-end ">
                <div className="pl-md">
                  <Login />
                </div>

                <div className="pl-md">
                  <Cart />
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>

      <nav id="nav-mobile" className="sm-only px-md">
        <ul className="flex items-center gap-md">
          <li>
            <Link to="/">{siteName}</Link>
          </li>
          <li>
            <Cart />
          </li>
          <li>
            <Login />
          </li>
          {nav.map((li, i) => (
            <div key={i} className="col-md-2 col-xs menu-item">
              <Link to={_linkResolver(li.link)} className="md:pr-md">
                {_localizeField(li.label)}
              </Link>
            </div>
          ))}
          <li>
            <LocaleSwitcher />
          </li>
          <li>
            <Switch size={12} event="TOGGLE_DARK_MODE" />
          </li>
        </ul>
      </nav>
      {/* <nav
        id="nav-mobile"
        className="fixed bg-black left-0 top-0 p-md pt-header-height z-0 w-full h-screen  hidden"
      >
        <div className="sm-only ">
          <Menu input={nav} />
          <ul className="flex flex-col ">
            <li>
              <LocaleSwitcher />
            </li>
            <li>
              <Login />
            </li>

            <li>
              <Cart />
            </li>
          </ul>
        </div>
      </nav>
      <Burger targetSelector="#nav-mobile" /> */}
    </header>
  )
}

export default Header
